import React from "react";
import { useTranslation, Trans, useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import TopHeader from "../components/top_header";
import ShareBox from "../components/share_box";
import Footer from "../components/footer";

import HeaderLogoImage from "../images/common/header_logo@2x.png";
import HakaseGifImage from "../images/common/img_hakase01.gif";
import LineFiveDotImage from "../images/common/line_5-dot@2x.png";
import StepStrategyImage from "../images/common/img_step@2x.png";
import ReverseStrategyImage from "../images/common/img_reverse@2x.png";
import CreateStrategyImage from "../images/common/img_create@2x.png";
import KnockStrategyImage from "../images/common/img_knock@2x.png";
import ScanStrategyImage from "../images/common/img_scan@2x.png";
import "../styles/entry.scss";

export default function MathMindContent() {
  const { t, i18n } = useTranslation();
  const { navigate } = useI18next();
  const currentLanguage = i18n.language;

  return (
    <Layout>
      <div className="entry">
        <TopHeader headerLogoImage={HeaderLogoImage} />
        <div id="title_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <h1 className="title">{t("mmind.entry.title")}</h1>
              </div>
            </div>
          </div>
        </div>

        <div id="title_description_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <p className="text">
                    <Trans i18nKey={"mmind.entry.description"} />
                  </p>
                  <p className="image_area">
                    <img
                      alt=""
                      className="img-responsive center-block icon_img"
                      src={HakaseGifImage}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="title_button_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <button
                  className="btn btn-default btn-block start_button"
                  id="moveToQuiz"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/mmind/quiz/");
                  }}
                >
                  {t("mmind.entry.start_button")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="title_image_large_column">
          <p className="image_area">
            <img
              alt=""
              className="img-responsive center-block icon_img"
              src={HakaseGifImage}
            />
          </p>
        </div>

        <div id="five_thinking_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div className="delimiter">
                  <img
                    alt=""
                    className="img-responsive center-block delimiter_img"
                    src={LineFiveDotImage}
                  />
                </div>
                <h1 className="title">{t("mmind.entry.ft_title")}</h1>
                <div
                  className={`description dynamic_line_height ${
                    currentLanguage === "en" ? "line_height_narrow" : ""
                  }`}
                >
                  <p>
                    <Trans i18nKey={"mmind.entry.ft_description"} />
                  </p>
                </div>
                <div className="image_box">
                  <img
                    alt="Step strategy"
                    className="result_image"
                    src={StepStrategyImage}
                  />
                  <img
                    alt="Reverse strategy"
                    className="result_image"
                    src={ReverseStrategyImage}
                  />
                  <img
                    alt="Create strategy"
                    className="result_image"
                    src={CreateStrategyImage}
                  />
                  <img
                    alt="Knock strategy"
                    className="result_image"
                    src={KnockStrategyImage}
                  />
                  <img
                    alt="Scan strategy"
                    className="result_image result_image_last"
                    src={ScanStrategyImage}
                  />
                </div>
                <button
                  className="btn btn-default btn-block learnmore_button"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/mmind/fthinking/");
                  }}
                >
                  {t("mmind.entry.ft_jump_button")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {currentLanguage !== "zh" ? <ShareBox /> : ""}

        <Footer />
      </div>

      <div id="fb-root" className=" fb_reset">
        <div
          style={{
            position: `absolute`,
            top: `-10000px`,
            width: `0px`,
            height: `0px`,
          }}
        >
          <div></div>
        </div>
      </div>
    </Layout>
  );
}
